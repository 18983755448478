export interface RCSAEntityMainDataModel {
  risk_code: string;
  period: string;
  risk_description: string;
  risk_causes?: any[];
  mitigation_descriptions?: string[];
  mitigation_status?: string[];
  monitoring_status?: string[];

  risk_cause?: string[];
  mitigation_description?: string[];

  risk_level: string;
  status_rcsa: string[];
  status_mitigation: string[];
  status_monitoring: string[];
  ulid?: string;
  user_id?: string;
  entity_id?: string;
  position_id?: string;
  project_assignment_id?: string;
  period_id?: string;
  taxonomy_risk_activity_id?: string;
  taxonomy_risk_description_id?: string;
  taxonomy_risk_description_text?: string;
  related_process?: string;
  taxonomy_risk_category_id?: string;
  taxonomy_sub_risk_category_id?: string;
  taxonomy_sub_detail_risk_category_id?: string;
  risk_existing_controls?: any[];
  criteria_inherent_impact?: string;
  criteria_inherent_impact_color?: string;
  criteria_inherent_impact_level?: string;
  criteria_inherent_likelihood?: string;
  criteria_inherent_likelihood_color?: string;
  criteria_inherent_likelihood_level?: string;
  criteria_residual_impact?: string;
  criteria_residual_impact_color?: string;
  criteria_residual_impact_level?: string;
  criteria_residual_likelihood?: string;
  criteria_residual_likelihood_color?: string;
  criteria_residual_likelihood_level?: string;
  inherent_impact_description?: string;
  inherent_likelihood_description?: string;
  residual_impact_description?: string;
  residual_likelihood_description?: string;
  latest_activity?: any[];
  risk_impact_categories?: any[];
  created_at?: string;
  code?: string;
  status?: string;
  level?: string;
  period_name?: string;
  risk_audit_findings?: any[];
  inherent_level?: string;
  inherent_color?: string;
  residual_level?: string;
  residual_color?: string;
}

export interface RCSADependencyDataModel {
  user_id: string;
  entity_id: string;
  position_id: string;
  position_name: string;
  division_name: string;
  directorate_name: string;
  project_assignment_id: string;
  period_id: string;
  period_code: string;
  period_name: string;
  assignment_information: any;
  taxonomy: any;
  criteria: any;
}

export interface RCSAPICModel {
  user_id: string;
  user_name: string;
  position_id: string;
  position_name: string;
  entity_id: string;
  entity_name: string;
  role_title: string;
  roles: string[];
}

export interface RCSAUnitModel {
  unit_id: string;
  unit_name: string;
  detail: any;
}

export interface RCSAIncidentModel {
  count_incident: number;
  sum_loss_amount: number;
}

export interface RCSAEntityModel {
  main: {
    data: RCSAEntityMainDataModel[];
    total: number;
  };
  rcsaDetail: {
    data: RCSAEntityMainDataModel;
  };
  dependency: {
    data: RCSADependencyDataModel;
    total: number;
  };
  pic: {
    data: RCSAPICModel[];
    total: number;
  };
  unit: {
    data: RCSAUnitModel[];
    total: number;
  };
  incident: {
    data: RCSAIncidentModel;
  };
  riskIndicator: {
    data: RCSAPICModel[];
    total: number;
  };
}

export enum RCSAEntityActionType {
  GetAllRCSAEntity = "⌘➝RCSA➝Entity➝GetAllRCSAEntity",
  GetDetailRCSAEntity = "⌘➝RCSA➝Entity➝GetDetailRCSAEntity",
  GetDependencyEntity = "⌘➝RCSA➝Entity➝GetDependencyEntity",
  GetUnitRCSAEntity = "⌘➝RCSA➝Entity➝GetUnitRCSAEntity",
  GetIncidentRCSAEntity = "⌘➝RCSA➝Entity➝GetIncidentRCSAEntity",
  GetPICEntity = "⌘➝RCSA➝Entity➝GetPICEntity",
  GetRiskIndicator = "⌘➝RCSA➝Entity➝GetRiskIndicator",
}

export type RCSAEntityAction = {
  data: {
    data: RCSAEntityMainDataModel[];
    total: number;
  };
  type: RCSAEntityActionType.GetAllRCSAEntity;
} | {
  data: {
    data: RCSADependencyDataModel;
    total: number;
  };
  type: RCSAEntityActionType.GetDependencyEntity;
} | {
  data: {
    data: RCSAPICModel[];
    total: number;
  };
  type: RCSAEntityActionType.GetPICEntity;
} | {
  data: {
    data: RCSAEntityMainDataModel;
  };
  type: RCSAEntityActionType.GetDetailRCSAEntity;
} | {
  data: {
    data: RCSAUnitModel[];
    total: number;
  };
  type: RCSAEntityActionType.GetUnitRCSAEntity;
} | {
  data: {
    data: RCSAPICModel[];
    total: number;
  };
  type: RCSAEntityActionType.GetRiskIndicator;
} | {
  data: {
    data: RCSAIncidentModel;
  };
  type: RCSAEntityActionType.GetIncidentRCSAEntity;
}
